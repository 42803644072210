<template>
  <div class="wrapper-rma-policy">
    <cms-content :id="rmaPolicyBlock" />
    <absolute-loader :showLoader="showLoader" />
  </div>
</template>

<script>
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'
import CmsContent from '@/components/CmsContent'

export default {
  name: 'RmaPolicy',
  data () {
    return {
      showLoader: false,
      rmaPolicyBlock: 'awrma-procedures-overlay-text'
    }
  },
  mounted () {
    if (process.client) {
      const _this = this
      this.showLoader = true
      return this.$store.dispatch('cms/loadContent', _this.rmaPolicyBlock)
        .finally(() => {
          _this.showLoader = false
        })
    }
  },
  components: {
    AbsoluteLoader,
    CmsContent
  }
}
</script>
